import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">About</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>
                  ここは、有限会社エイビープラスの生産技術研究所の活動記録の体をした、本質的には中の人のブログです。
                </h5>
                <p>
                  しばらく前から、キーボード沼にはまっています。
                </p>
                <p>
                  一年以上もキーボードの自作に励んでいると、いろいろと課題にぶつかったり、ノウハウがたまってきます。
                </p>
                <p>
                  それを吐き出さないのは、もったいないので、ブログを始めることにしました。
                </p>
                <p>
                  キーボードと言えば、プログラマの生産性に直結するので、これは生産技術研究と言ってもいいのではないか。ということで、ABplus生産技術研究所が産まれました。厳密に言うと、キーボードの自作は、ガジェット分室の管轄になります。
                </p>
                <p>
                  このように、キーボードの自作がメインコンテンツですが、プログラム言語やプロジェクトマネジメントについてのポエムも投稿しようかなとも思っています。
                </p>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
